/*!
 KeyTable 2.5.2
 ©2009-2020 SpryMedia Ltd - datatables.net/license
*/
(function(e){"function"===typeof define&&define.amd?define(["jquery","datatables.net"],function(k){return e(k,window,document)}):"object"===typeof exports?module.exports=function(k,i){k||(k=window);if(!i||!i.fn.dataTable)i=require("datatables.net")(k,i).$;return e(i,k,k.document)}:e(jQuery,window,document)})(function(e,k,i,o){var l=e.fn.dataTable,p=0,m=function(a,b){if(!l.versionCheck||!l.versionCheck("1.10.8"))throw"KeyTable requires DataTables 1.10.8 or newer";this.c=e.extend(!0,{},l.defaults.keyTable,
m.defaults,b);this.s={dt:new l.Api(a),enable:!0,focusDraw:!1,waitingForDraw:!1,lastFocus:null,namespace:".keyTable-"+p++,tabInput:null};this.dom={};var c=this.s.dt.settings()[0],d=c.keytable;if(d)return d;c.keytable=this;this._constructor()};e.extend(m.prototype,{blur:function(){this._blur()},enable:function(a){this.s.enable=a},focus:function(a,b){this._focus(this.s.dt.cell(a,b))},focused:function(a){if(!this.s.lastFocus)return!1;var b=this.s.lastFocus.cell.index();return a.row===b.row&&a.column===
b.column},_constructor:function(){this._tabInput();var a=this,b=this.s.dt,c=e(b.table().node()),d=this.s.namespace,f=!1;"static"===c.css("position")&&c.css("position","relative");e(b.table().body()).on("click"+d,"th, td",function(d){if(!1!==a.s.enable){var c=b.cell(this);c.any()&&a._focus(c,null,!1,d)}});e(i).on("keydown"+d,function(b){f||a._key(b)});if(this.c.blurable)e(i).on("mousedown"+d,function(d){e(d.target).parents(".dataTables_filter").length&&a._blur();e(d.target).parents().filter(b.table().container()).length||
e(d.target).parents("div.DTE").length||e(d.target).parents("div.editor-datetime").length||e(d.target).parents().filter(".DTFC_Cloned").length||a._blur()});if(this.c.editor){var j=this.c.editor;j.on("open.keyTableMain",function(b,c){"inline"!==c&&a.s.enable&&(a.enable(!1),j.one("close"+d,function(){a.enable(!0)}))});if(this.c.editOnFocus)b.on("key-focus"+d+" key-refocus"+d,function(b,d,c,f){a._editor(null,f,!0)});b.on("key"+d,function(b,d,c,f,e){a._editor(c,e,!1)});e(b.table().body()).on("dblclick"+
d,"th, td",function(d){!1!==a.s.enable&&b.cell(this).any()&&a._editor(null,d,!0)});j.on("preSubmit",function(){f=!0}).on("preSubmitCancelled",function(){f=!1}).on("submitComplete",function(){f=!1})}if(b.settings()[0].oFeatures.bStateSave)b.on("stateSaveParams"+d,function(b,d,c){c.keyTable=a.s.lastFocus?a.s.lastFocus.cell.index():null});b.on("column-visibility"+d,function(){a._tabInput()});b.on("draw"+d,function(d){a._tabInput();if(!a.s.focusDraw){var c=a.s.lastFocus;if(c&&c.node&&e(c.node).closest("body")===
i.body){var c=a.s.lastFocus.relative,f=b.page.info(),g=c.row+f.start;0!==f.recordsDisplay&&(g>=f.recordsDisplay&&(g=f.recordsDisplay-1),a._focus(g,c.column,!0,d))}}});this.c.clipboard&&this._clipboard();b.on("destroy"+d,function(){a._blur(!0);b.off(d);e(b.table().body()).off("click"+d,"th, td").off("dblclick"+d,"th, td");e(i).off("mousedown"+d).off("keydown"+d).off("copy"+d).off("paste"+d)});var g=b.state.loaded();if(g&&g.keyTable)b.one("init",function(){var a=b.cell(g.keyTable);a.any()&&a.focus()});
else this.c.focus&&b.cell(this.c.focus).focus()},_blur:function(a){if(this.s.enable&&this.s.lastFocus){var b=this.s.lastFocus.cell;e(b.node()).removeClass(this.c.className);this.s.lastFocus=null;a||(this._updateFixedColumns(b.index().column),this._emitEvent("key-blur",[this.s.dt,b]))}},_clipboard:function(){var a=this.s.dt,b=this,c=this.s.namespace;k.getSelection&&(e(i).on("copy"+c,function(a){var a=a.originalEvent,c=k.getSelection().toString(),e=b.s.lastFocus;!c&&e&&(a.clipboardData.setData("text/plain",
e.cell.render(b.c.clipboardOrthogonal)),a.preventDefault())}),e(i).on("paste"+c,function(d){var d=d.originalEvent,c=b.s.lastFocus,e=i.activeElement,g=b.c.editor,h;if(c&&(!e||"body"===e.nodeName.toLowerCase()))d.preventDefault(),k.clipboardData&&k.clipboardData.getData?h=k.clipboardData.getData("Text"):d.clipboardData&&d.clipboardData.getData&&(h=d.clipboardData.getData("text/plain")),g?g.inline(c.cell.index()).set(g.displayed()[0],h).submit():(c.cell.data(h),a.draw(!1))}))},_columns:function(){var a=
this.s.dt,b=a.columns(this.c.columns).indexes(),c=[];a.columns(":visible").every(function(a){-1!==b.indexOf(a)&&c.push(a)});return c},_editor:function(a,b,c){if(this.s.lastFocus){var d=this,f=this.s.dt,j=this.c.editor,g=this.s.lastFocus.cell,h=this.s.namespace;if(!e("div.DTE",g.node()).length&&!(null!==a&&(0<=a&&9>=a||11===a||12===a||14<=a&&31>=a||112<=a&&123>=a||127<=a&&159>=a))){b.stopPropagation();13===a&&b.preventDefault();var n=function(){j.one("open"+h,function(){j.off("cancelOpen"+h);c||e("div.DTE_Field_InputControl input, div.DTE_Field_InputControl textarea").select();
f.keys.enable(c?"tab-only":"navigation-only");f.on("key-blur.editor",function(a,b,c){j.displayed()&&c.node()===g.node()&&j.submit()});c&&e(f.table().container()).addClass("dtk-focus-alt");j.on("preSubmitCancelled"+h,function(){setTimeout(function(){d._focus(g,null,!1)},50)});j.on("submitUnsuccessful"+h,function(){d._focus(g,null,!1)});j.one("close",function(){f.keys.enable(!0);f.off("key-blur.editor");j.off(h);e(f.table().container()).removeClass("dtk-focus-alt")})}).one("cancelOpen"+h,function(){j.off(h)}).inline(g.index())};
13===a?(c=!0,e(i).one("keyup",function(){n()})):n()}}},_emitEvent:function(a,b){this.s.dt.iterator("table",function(c){e(c.nTable).triggerHandler(a,b)})},_focus:function(a,b,c,d){var f=this,j=this.s.dt,g=j.page.info(),h=this.s.lastFocus;d||(d=null);if(this.s.enable){if("number"!==typeof a){if(!a.any())return;var n=a.index(),b=n.column,a=j.rows({filter:"applied",order:"applied"}).indexes().indexOf(n.row);if(0>a)return;g.serverSide&&(a+=g.start)}if(-1!==g.length&&(a<g.start||a>=g.start+g.length))this.s.focusDraw=
!0,this.s.waitingForDraw=!0,j.one("draw",function(){f.s.focusDraw=!1;f.s.waitingForDraw=!1;f._focus(a,b,o,d)}).page(Math.floor(a/g.length)).draw(!1);else if(-1!==e.inArray(b,this._columns())){g.serverSide&&(a-=g.start);g=j.cells(null,b,{search:"applied",order:"applied"}).flatten();g=j.cell(g[a]);if(h){if(h.node===g.node()){this._emitEvent("key-refocus",[this.s.dt,g,d||null]);return}this._blur()}this._removeOtherFocus();h=e(g.node());h.addClass(this.c.className);this._updateFixedColumns(b);if(c===
o||!0===c)this._scroll(e(k),e(i.body),h,"offset"),c=j.table().body().parentNode,c!==j.table().header().parentNode&&(c=e(c.parentNode),this._scroll(c,c,h,"position"));this.s.lastFocus={cell:g,node:g.node(),relative:{row:j.rows({page:"current"}).indexes().indexOf(g.index().row),column:g.index().column}};this._emitEvent("key-focus",[this.s.dt,g,d||null]);j.state.save()}}},_key:function(a){if(this.s.waitingForDraw)a.preventDefault();else{var b=this.s.enable,c=!0===b||"navigation-only"===b;if(b&&(!(0===
a.keyCode||a.ctrlKey||a.metaKey||a.altKey)||a.ctrlKey&&a.altKey)){var d=this.s.lastFocus;if(d)if(this.s.dt.cell(d.node).any()){var d=this.s.dt,f=this.s.dt.settings()[0].oScroll.sY?!0:!1;if(!(this.c.keys&&-1===e.inArray(a.keyCode,this.c.keys)))switch(a.keyCode){case 9:this._shift(a,a.shiftKey?"left":"right",!0);break;case 27:this.s.blurable&&!0===b&&this._blur();break;case 33:case 34:c&&!f&&(a.preventDefault(),d.page(33===a.keyCode?"previous":"next").draw(!1));break;case 35:case 36:c&&(a.preventDefault(),
b=d.cells({page:"current"}).indexes(),c=this._columns(),this._focus(d.cell(b[35===a.keyCode?b.length-1:c[0]]),null,!0,a));break;case 37:c&&this._shift(a,"left");break;case 38:c&&this._shift(a,"up");break;case 39:c&&this._shift(a,"right");break;case 40:c&&this._shift(a,"down");break;case 113:if(this.c.editor){this._editor(null,a,!0);break}default:!0===b&&this._emitEvent("key",[d,a.keyCode,this.s.lastFocus.cell,a])}}else this.s.lastFocus=null}}},_removeOtherFocus:function(){var a=this.s.dt.table().node();
e.fn.dataTable.tables({api:!0}).iterator("table",function(){this.table().node()!==a&&this.cell.blur()})},_scroll:function(a,b,c,d){var f=c[d](),e=c.outerHeight(),g=c.outerWidth(),h=b.scrollTop(),n=b.scrollLeft(),i=a.height(),a=a.width();"position"===d&&(f.top+=parseInt(c.closest("table").css("top"),10));f.top<h&&b.scrollTop(f.top);f.left<n&&b.scrollLeft(f.left);f.top+e>h+i&&e<i&&b.scrollTop(f.top+e-i);f.left+g>n+a&&g<a&&b.scrollLeft(f.left+g-a)},_shift:function(a,b,c){var d=this.s.dt,f=d.page.info(),
j=f.recordsDisplay,g=this.s.lastFocus.cell,h=this._columns();if(g){var i=d.rows({filter:"applied",order:"applied"}).indexes().indexOf(g.index().row);f.serverSide&&(i+=f.start);d=d.columns(h).indexes().indexOf(g.index().column);f=h[d];"right"===b?d>=h.length-1?(i++,f=h[0]):f=h[d+1]:"left"===b?0===d?(i--,f=h[h.length-1]):f=h[d-1]:"up"===b?i--:"down"===b&&i++;0<=i&&i<j&&-1!==e.inArray(f,h)?(a&&a.preventDefault(),this._focus(i,f,!0,a)):!c||!this.c.blurable?a&&a.preventDefault():this._blur()}},_tabInput:function(){var a=
this,b=this.s.dt,c=null!==this.c.tabIndex?this.c.tabIndex:b.settings()[0].iTabIndex;-1!=c&&(this.s.tabInput||(c=e('<div><input type="text" tabindex="'+c+'"/></div>').css({position:"absolute",height:1,width:0,overflow:"hidden"}),c.children().on("focus",function(c){var f=b.cell(":eq(0)",a._columns(),{page:"current"});f.any()&&a._focus(f,null,!0,c)}),this.s.tabInput=c),(c=this.s.dt.cell(":eq(0)","0:visible",{page:"current",order:"current"}).node())&&e(c).prepend(this.s.tabInput))},_updateFixedColumns:function(a){var b=
this.s.dt,c=b.settings()[0];if(c._oFixedColumns){var d=c.aoColumns.length-c._oFixedColumns.s.iRightColumns;(a<c._oFixedColumns.s.iLeftColumns||a>=d)&&b.fixedColumns().update()}}});m.defaults={blurable:!0,className:"focus",clipboard:!0,clipboardOrthogonal:"display",columns:"",editor:null,editOnFocus:!1,focus:null,keys:null,tabIndex:null};m.version="2.5.2";e.fn.dataTable.KeyTable=m;e.fn.DataTable.KeyTable=m;l.Api.register("cell.blur()",function(){return this.iterator("table",function(a){a.keytable&&
a.keytable.blur()})});l.Api.register("cell().focus()",function(){return this.iterator("cell",function(a,b,c){a.keytable&&a.keytable.focus(b,c)})});l.Api.register("keys.disable()",function(){return this.iterator("table",function(a){a.keytable&&a.keytable.enable(!1)})});l.Api.register("keys.enable()",function(a){return this.iterator("table",function(b){b.keytable&&b.keytable.enable(a===o?!0:a)})});l.Api.register("keys.move()",function(a){return this.iterator("table",function(b){b.keytable&&b.keytable._shift(null,
a,!1)})});l.ext.selector.cell.push(function(a,b,c){var b=b.focused,a=a.keytable,d=[];if(!a||b===o)return c;for(var f=0,e=c.length;f<e;f++)(!0===b&&a.focused(c[f])||!1===b&&!a.focused(c[f]))&&d.push(c[f]);return d});e(i).on("preInit.dt.dtk",function(a,b){if("dt"===a.namespace){var c=b.oInit.keys,d=l.defaults.keys;if(c||d)d=e.extend({},d,c),!1!==c&&new m(b,d)}});return m});
