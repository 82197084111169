// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

import "@hotwired/turbo-rails"

import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

// include moment in global and window scope (so you can access it globally)
var moment = require('moment')
global.moment = moment;
window.moment = moment;

// require("apexcharts") // expose to window
import ApexCharts from 'apexcharts'
window.ApexCharts = ApexCharts
global.ApexCharts = ApexCharts

// import ApexCharts from 'apexcharts'
// window.ApexCharts = ApexCharts


require("bootstrap");
// import the application.scss we created for the CSS
import "../css/application.scss";


import "../css/vendor/britecharts.min.css";
import "../css/vendor/buttons.bootstrap4.css";
import "../css/vendor/dataTables.bootstrap4.css";
import "../css/vendor/frappe-gantt.css";
import "../css/vendor/fullcalendar.min.css";
import "../css/vendor/quill.bubble.css";
import "../css/vendor/quill.core.css";
import "../css/vendor/quill.snow.css";
import "../css/vendor/responsive.bootstrap4.css";
import "../css/vendor/select.bootstrap4.css";
import "../css/vendor/simplemde.min.css";

import "vectormap";
import "react";
// import "apexcharts";
import "britecharts";
import "jquery.rateit";
import "dragula";
import "jquery-ui";
import "dropzone";
import "quill";
import "frappe-gantt";
import "fullcalendar";
import "simplemde";
import "d3";
import "handlebars";
import "select2";
import "daterangepicker";
import "resolve-url-loader"
// import "gmaps/gmaps"; # revisar luego


import "../vendor/dataTables.bootstrap4";

import "../src/buttons.bootstrap4.min";
import "../src/buttons.flash.min";
import "../src/buttons.html5.min";
import "../src/buttons.print.min";
import "../src/Chart.bundle.min";
import "../src/dataTables.buttons.min";
import "../src/dataTables.checkboxes.min";
import "../src/dataTables.keyTable.min";
import "../src/dataTables.responsive.min";
import "../src/dataTables.select.min";
import "../src/ion.rangeSlider.min";
import "../src/jquery.dataTables.min";
import "../src/jquery.sparkline.min";
import "../src/responsive.bootstrap4.min";
import "../src/typeahead.bundle.min";

import "../src/jquery-jvectormap-1.2.2.min";
import "../vendor/jquery-jvectormap-au-mill-en";
import "../vendor/jquery-jvectormap-ca-lcc-en";
import "../vendor/jquery-jvectormap-es-merc";
import "../vendor/jquery-jvectormap-es-mill";
import "../vendor/jquery-jvectormap-europe-mill-en";
import "../vendor/jquery-jvectormap-fr-merc-en";
import "../vendor/jquery-jvectormap-in-mill-en";
import "../vendor/jquery-jvectormap-uk-mill-en";
import "../vendor/jquery-jvectormap-us-il-chicago-mill-en";
import "../vendor/jquery-jvectormap-us-merc-en";
import "../vendor/jquery-jvectormap-world-mill-en";

// copy all static images under ../img and ../svg to the output folder,
// and you can reference them with <%= image_pack_tag 'media/img/abc.png' %> or <%= image_pack_tag 'media/svg/def.svg' %>
const images = require.context("../images", true)
const imagePath = (name) => images(name, true)

const fonts = require.context("../fonts", true)
const fontPath = (name) => fonts(name, true)

import "controllers";

require("@nathanvda/cocoon");

// import "packs/charts";



